import React, {} from "react";
import { Box } from "@mui/material";
import starrySkyVideo from "../../../videos/Starry-Sky.webm";
import starrySkyPoster from "../../../images/starry-sky-first-frame.webp";
import HomePageFullScreenSection from "./HomePageFullScreenSection";
import VideoModal from "./VideoModal";
import video from "../../../videos/OpportunityVideo.webm";

function BBO1() {
  return (
    <HomePageFullScreenSection backgroundColor="transparent">
      <Box
        sx={{
          zIndex: 10,
          height: "100%",
          width: "100%",
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          userSelect: 'none',
        }}
      >
        <VideoModal text="Welcome to the Revolution" video={video} />
      </Box>
      {/* video background */}
      <Box
        sx={{
          height: "100vh",
          width: "100vw",
          position: "fixed",
          overflow: "hidden",
          top: 0,
          backgroundColor: "#000",
          left: 0,
          zIndex: -1,
          opacity: 0.93,
          "& video": {
            height: "100%",
            width: "100%",
            objectFit: "cover",
            objectPosition: "center center",
          },
        }}
      >
        <video
          id="bgvideo"
          src={starrySkyVideo}
          poster={starrySkyPoster}
          disablePictureInPicture
          playsInline
          autoPlay
          loop
          volume={0}
          muted
        />
      </Box>
    </HomePageFullScreenSection>
  );
}

export default BBO1;
