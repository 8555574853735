import React from "react";
import { useLocation } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import gsap from "gsap";
import { ScrollTrigger, ScrollSmoother } from "gsap/all";
import NavbarLinkWrapper from "./NavbarLinkWrapper";

gsap.registerPlugin(ScrollTrigger, ScrollSmoother);

function StaticNavbarLink({ sectionTitle, invertNavbar }) {
  const containerRef = React.useRef();
  const activeSectionIndicatorRef = React.useRef();
  const location = useLocation();
  const isCurrentSection = React.useRef(false);

  // set Navbar functionality based on route location
  React.useEffect(() => {
    // is this the current section?
    if (location.pathname.includes(sectionTitle)) {
      isCurrentSection.current = true;
      gsap.to(activeSectionIndicatorRef.current, {
        autoAlpha: 1,
        duration: 0.2,
        y: 0,
      });
    } else {
      // not current section
      isCurrentSection.current = false;
      gsap.set(activeSectionIndicatorRef.current, {
        autoAlpha: 0,
      });
      // setup underline functionality for not the current section
      containerRef.current.addEventListener("mouseenter", handleMouseEnter);
      containerRef.current.addEventListener("mouseleave", handleMouseLeave);
    }

    // eslint-disable-next-line
  }, [location]);

  function handleMouseEnter() {
    if (!isCurrentSection.current) {
      gsap.killTweensOf(activeSectionIndicatorRef.current);
      gsap.fromTo(
        activeSectionIndicatorRef.current,
        { autoAlpha: 0, y: 5 },
        {
          autoAlpha: 1,
          y: 0,
          duration: 0.3,
          ease: "power1.inOut",
        },
      );
    }
  }

  function handleMouseLeave() {
    if (!isCurrentSection.current) {
      gsap.killTweensOf(activeSectionIndicatorRef.current);
      gsap.to(activeSectionIndicatorRef.current, {
        autoAlpha: 0,
        y: 5,
        duration: 0.3,
        ease: "power1.inOut",
      });
    }
  }

  const LinkText = styled(Typography)(({ theme }) => ({
    textTransform: "uppercase",
    fontSize: "15px",
  }));

  return (
    <NavbarLinkWrapper sectionTitle={sectionTitle}>
      <Box
        ref={containerRef}
        sx={{
          padding: "0 14px",
          position: "relative",
          cursor: "pointer",
        }}
      >
        <LinkText>{sectionTitle ? sectionTitle : "Priority 6"}</LinkText>
        <Box
          ref={activeSectionIndicatorRef}
          sx={{
            height: "5px",
            width: "calc(100% - 28px)",
            // backgroundColor: invertNavbar ? 'darkblue' : 'white',
            backgroundColor: 'darkblue',
            position: "absolute",
            bottom: "-4px",
            left: "14px",
            visibility: "hidden",
          }}
        ></Box>
      </Box>
    </NavbarLinkWrapper>
  );
}

export default StaticNavbarLink;
