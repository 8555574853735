import * as React from "react";
import { Box, List, ListItem, Divider } from "@mui/material";
import { useTheme } from "@mui/material";
import MenuLink from "./MenuLink";
import gsap from "gsap";
import { ScrollTrigger, ScrollSmoother } from "gsap/all";
import { useNavigate } from "react-router-dom";

gsap.registerPlugin(ScrollTrigger, ScrollSmoother);

function Menu({ menuOpen, toggleMenuOpen, handleLogout }) {
  const userRole = localStorage.getItem('userRole');
  const pages = ["/home", "/meet-team", "/schedule-meeting"];
  const pageNames = ["Home", "The Team", "Request Information"];
  const theme = useTheme();
  const menuRef = React.useRef();
  const showMenuTL = React.useRef();
  const hideMenuTL = React.useRef();
  const hideMenuFromLinkClickTL = React.useRef();
  const init = React.useRef(true);
  const smoother = ScrollSmoother.get();
  const navigate = useNavigate();

  let menuItemRefs = [];
  pages.forEach((page) => {
    menuItemRefs.push(React.createRef());
  });

  const manageUsersMenuItemRef = React.useRef();
  const logoutMenuItemRef = React.useRef();

  React.useEffect(() => {
    init.current = true;
  }, []);

  React.useEffect(() => {
    if (init.current === true) {
      init.current = false;
    } else {
      menuOpen ? showMenu() : hideMenu();
    }
    // eslint-disable-next-line
  }, [menuOpen]);

  const menuBackgroundDuration = 0.4;

  function showMenu() {
    showMenuTL.current = gsap.timeline({
      onStart: () => {
        smoother.paused(true);
      },
    });
    showMenuTL.current.to(menuRef.current, {
      autoAlpha: 1,
      duration: menuBackgroundDuration,
    });
    menuItemRefs.forEach((ref, i) => {
      showMenuTL.current.fromTo(
        ref.current,
        { autoAlpha: 0, scale: 1.3, transformOrigin: "50% 50%" },
        {
          autoAlpha: 1,
          scale: 1,
          transformOrigin: "50% 50%",
          duration: 0.3,
          ease: "power1.inOut",
        },
        "-=0.2",
      );
    });
    showMenuTL.current.fromTo(
      manageUsersMenuItemRef.current,
      { autoAlpha: 0, scale: 1.3, transformOrigin: "50% 50%" },
      {
        autoAlpha: 1,
        scale: 1,
        transformOrigin: "50% 50%",
        duration: 0.3,
        ease: "power1.inOut",
      },
      "-=0.2"
    );
    showMenuTL.current.fromTo(
      logoutMenuItemRef.current,
      { autoAlpha: 0, scale: 1.3, transformOrigin: "50% 50%" },
      {
        autoAlpha: 1,
        scale: 1,
        transformOrigin: "50% 50%",
        duration: 0.3,
        ease: "power1.inOut",
      },
      "-=0.2"
    );
  }

  function hideMenu() {
    if (gsap.getProperty(menuRef.current, "visibility") !== "hidden") {
      hideMenuTL.current = gsap.timeline({
        onComplete: () => {
          smoother.paused(false);
        },
      });
      menuItemRefs.forEach((ref, i) => {
        hideMenuTL.current.to(
          ref.current,
          {
            autoAlpha: 0,
            scale: 0.66,
            transformOrigin: "50% 50%",
            duration: 0.2,
          },
          i === 0 ? "0" : "-=0.1",
        );
      });
      hideMenuTL.current.to(
        manageUsersMenuItemRef.current,
        {
          autoAlpha: 0,
          scale: 0.66,
          transformOrigin: "50% 50%",
          duration: 0.2,
        },
        "-=0.1"
      );
      hideMenuTL.current.to(
        logoutMenuItemRef.current,
        {
          autoAlpha: 0,
          scale: 0.66,
          transformOrigin: "50% 50%",
          duration: 0.2,
        },
        "-=0.1"
      );
      hideMenuTL.current.to(menuRef.current, {
        autoAlpha: 0,
        duration: menuBackgroundDuration,
      });
    }
  }

  function hideMenuFromLinkClick() {
    hideMenuFromLinkClickTL.current = gsap.timeline({
      onStart: () => {
        smoother.paused(false);
        gsap.delayedCall(0.1, () => {
          toggleMenuOpen(!menuOpen);
        });
      },
      onComplete: () => {},
    });
    menuItemRefs.forEach((ref, i) => {
      hideMenuFromLinkClickTL.current.to(
        ref.current,
        {
          autoAlpha: 0,
          duration: 0.3,
        },
        0,
      );
    });
    hideMenuFromLinkClickTL.current.to(manageUsersMenuItemRef.current, {
      autoAlpha: 0,
      duration: 0.3,
    });
    hideMenuFromLinkClickTL.current.to(logoutMenuItemRef.current, {
      autoAlpha: 0,
      duration: 0.3,
    });
    hideMenuFromLinkClickTL.current.to(menuRef.current, {
      autoAlpha: 0,
      duration: menuBackgroundDuration,
    });
  }

  const handleLogoutClick = () => {
    toggleMenuOpen(false);
    handleLogout();
    navigate("/");
  };

  const handleManageUsersClick = () => {
    toggleMenuOpen(false);
    navigate("/user-management");
  };

  console.log("user role: ", userRole);
  return (
    <Box
      ref={menuRef}
      sx={{
        height: "100vh",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: theme.palette.colors.darkBlue,
        position: "fixed",
        zIndex: 100,
        top: 0,
        left: 0,
        visibility: "hidden",
      }}
    >
      <List>
        {pages.map((page, i) => (
          <ListItem
            ref={menuItemRefs[i]}
            onClick={hideMenuFromLinkClick}
            key={`wrapper-for-link-to-${page}`}
            sx={{
              justifyContent: "center",
              paddingTop: 0,
              paddingBottom: 0,
              marginBottom: "0.5em",
            }}

          >
            <MenuLink key={`link-to-${page}`} to={page}>
              {pageNames[i]}
            </MenuLink>
          </ListItem>
        ))}
        {userRole === 'admin' && (
          <ListItem
            ref={manageUsersMenuItemRef}
            onClick={handleManageUsersClick}
            sx={{
              justifyContent: "center",
              paddingTop: 0,
              paddingBottom: 0,
              marginBottom: "0.5em",
            }}
          >
            <MenuLink>Manage Users</MenuLink>
          </ListItem>
        )}
        <Divider sx={{ marginTop: "1em", marginBottom: "1em" }} />
        <ListItem
          ref={logoutMenuItemRef}
          onClick={handleLogoutClick}
          sx={{
            justifyContent: "center",
            paddingTop: 0,
            paddingBottom: 0,
            marginBottom: "0.5em",
          }}
        >
          <MenuLink>Logout</MenuLink>
        </ListItem>
      </List>
    </Box>
  );
}

export default Menu;
