import React from "react";
import { Box } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import gsap from "gsap";

const SectionTransitionWipe = React.forwardRef(function SectionTransitionWipe(
  { props },
  ref,
) {
  const whitePanelRef = React.useRef();
  const bluePanelRef = React.useRef();
  const contentRef = React.useRef();
  const transitionTL = React.useRef();
  const theme = useTheme();

  const TransitionPanel = styled(Box)(({ theme }) => ({
    height: "100vh",
    width: "100%",
    position: "fixed",
    visibility: "hidden",
    top: 0,
    left: 0,
  }));

  const ContentWrapper = styled(Box)({
    height: "100vh",
    width: "100%",
    position: "fixed",
    top: 0,
    left: 0,
    visibility: "hidden",
    overflow: "auto",
  });

  // settings
  const firstPanelDuration = 0.55;
  const secondPanelDuration = 0.7;
  const endPanelDuration = 0.7;

  function transitionUp(content) {
    // Existing setup for the transition panels
    gsap.set(whitePanelRef.current, { zIndex: 102 });
    gsap.set(bluePanelRef.current, { zIndex: 101 });
    gsap.set([bluePanelRef.current, whitePanelRef.current], {
      scaleY: 0,
      autoAlpha: 1,
      transformOrigin: "50% 0%",
    });

    gsap.set(contentRef.current, { autoAlpha: 0, y: "20%" });

    if (content) {
      contentRef.current.innerHTML = content;
    }

    // Start the timeline for the transition
    transitionTL.current = gsap.timeline();

    // Panel animations
    transitionTL.current
      .to(bluePanelRef.current, {
        scaleY: 1,
        duration: firstPanelDuration,
        ease: "power2.inOut",
      })
      .to(whitePanelRef.current, {
        scaleY: 1,
        duration: secondPanelDuration,
        ease: "power2.inOut",
      }, `-=${firstPanelDuration / 2}`,)
      .set(bluePanelRef.current, { autoAlpha: 0 })
      .to(contentRef.current, {
        autoAlpha: 1,
        y: "0%",
        duration: endPanelDuration,
        ease: "power2.out",
      }, `-=${secondPanelDuration / 2}`)
      .to(whitePanelRef.current, {
        scaleY: 0,
        duration: endPanelDuration,
        transformOrigin: "50% 100%",
        ease: "power1.inOut",
      }, `-=${endPanelDuration / 1.5}`);

    return transitionTL.current;
  }

  function transitionDown(content) {
    // Existing setup for the transition panels
    gsap.set(whitePanelRef.current, { zIndex: 101 });
    gsap.set(bluePanelRef.current, { zIndex: 102 });
    gsap.set([whitePanelRef.current, bluePanelRef.current], {
      scaleY: 0,
      autoAlpha: 1,
      transformOrigin: "50% 100%",
    });
    gsap.set(contentRef.current, { autoAlpha: 0, y: "-20%" });

    if (content) {
      contentRef.current.innerHTML = content;
    }

    // Start the timeline for the transition
    transitionTL.current = gsap.timeline();

    // Panel animations
    transitionTL.current
      .to(whitePanelRef.current, {
        scaleY: 1,
        duration: firstPanelDuration,
        ease: "power2.inOut",
      })
      .to(bluePanelRef.current, {
        scaleY: 1,
        duration: secondPanelDuration,
        ease: "power2.inOut",
      }, `-=${firstPanelDuration / 2}`)
      .set(whitePanelRef.current, { autoAlpha: 0 })
      .to(contentRef.content, {
        autoAlpha: 1,
        y: "0%",
        duration: endPanelDuration,
        ease: "power2.out",
      }, `-=${secondPanelDuration / 2}`)
      .to(bluePanelRef.current, {
        scaleY: 0,
        duration: endPanelDuration,
        transformOrigin: "50% 0%",
        ease: "power2.inOut",
      }, `-=${endPanelDuration / 1.5}`);

    return transitionTL.current;
  }

  React.useImperativeHandle(ref, () => ({
    transitionUp,
    transitionDown,
  }));

  return (
    <>
      <TransitionPanel
        ref={whitePanelRef}
        sx={{
          backgroundColor: "#fff",
        }}
      />
      <TransitionPanel
        ref={bluePanelRef}
        sx={{
          backgroundColor: theme.palette.colors.darkBlue,
        }}
      />
      <ContentWrapper ref={contentRef} />
    </>
  );
});

export default SectionTransitionWipe;
