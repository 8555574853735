import React from 'react';
import { useLocation } from "react-router-dom";
import { Typography } from '@mui/material';
import styled from '@emotion/styled';

const CopyrightNotice = () => {
  const location = useLocation();
  const isUserManagementPage = location.pathname === '/user-management';
  const isLoginPage = location.pathname === '/';

  const StyledTypography = styled(Typography)({
    color: '#4d84e1',
    fontSize: '12px',
    fontFamily: "Normal Light",
    letterSpacing: "3px",
    height: '20px',
    textAlign: 'center',
    position: 'fixed',
    bottom: '7px',
    left: '0px',
    right: '0px',
    top: 'auto',
    userSelect: 'none',  // Add this line to make the text non-selectable
    // Media query for smaller screens
    '@media (max-width: 600px)': {
      fontSize: '8px' // Adjust the size as necessary for your design
    },
  });

  return (
    <div>
      {!isUserManagementPage && !isLoginPage && (
        <StyledTypography>
          <span>Confidential and Proprietary. </span>
          <span>Copyright © by Priority 6, Inc. All Rights Reserved.</span>
        </StyledTypography>
      )}
    </div>
  );
};

export default CopyrightNotice;
