import React from "react";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import gsap from "gsap";

function MenuLink(props) {
  const linkRef = React.useRef();

  const StyledLink = styled(Link)(({ theme }) => ({
    color: "#fff",
    fontFamily: "Normal Bold",
    textTransform: "uppercase",
    textUnderlineOffset: "10px",
    textDecorationThickness: "2px",
    textDecorationColor: "rgba(255, 255, 255, 0)",
    fontSize: "22px",
    [theme.breakpoints.up("md")]: {
      fontSize: "42px",
      textDecorationThickness: "3px",
    },
  }));

  React.useEffect(() => {
    const link = linkRef.current;
    linkRef.current.addEventListener("mouseenter", handleMouseEnter);
    linkRef.current.addEventListener("mouseleave", handleMouseLeave);

    function handleMouseEnter() {
      gsap.killTweensOf(linkRef.current);
      gsap.to(linkRef.current, {
        textDecorationColor: "rgba(255,255,255,1)",
        textUnderlineOffset: "4px",
        duration: 0.3,
        ease: "power1.out",
      });
    }

    function handleMouseLeave() {
      gsap.killTweensOf(linkRef.current);
      gsap.to(linkRef.current, {
        textDecorationColor: "rgba(255,255,255,0)",
        textUnderlineOffset: "10px",
        duration: 0.2,
        ease: "power1.in",
      });
    }

    function cleanupListeners() {
      link.removeEventListener("mouseenter", handleMouseEnter);
      link.removeEventListener("mouseleave", handleMouseLeave);
    }

    return () => cleanupListeners();
  });

  return (
    <div>
      <StyledLink to={props.to} ref={linkRef}>
        {props.children}
      </StyledLink>
    </div>
  );
}

export default MenuLink;
