import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Grid } from "@mui/material";
import { styled } from "@mui/system";
import HomePageFullScreenSection from "./HomePageFullScreenSection";
import "../../../styles/split-hover.css";

function Splitter({ firstLink, secondLink }) {
  const navigate = useNavigate();

  function handleLinkClick(path) {
    if (path) {
      navigate(path);
    }
  }

  const SplitBox = styled(Box)(() => ({
    display: "flex",
    height: "100%",
    flexGrow: 1,
    justifyContent: "center",
    alignItems: "center",
  }));

  return (
    <HomePageFullScreenSection>
      <Grid
        className="splitter"
        container
        sx={{
          height: "100%",
        }}
      >
        <Grid item xs={12} lg={6}>
          <SplitBox
            onClick={() => handleLinkClick(firstLink.path)}
            className="splitter-item"
          >
            <Box className="splitter-button">{firstLink.text}</Box>
          </SplitBox>
        </Grid>
        <Grid item xs={12} lg={6}>
          <SplitBox
            className="splitter-item"
            onClick={() => handleLinkClick(secondLink.path)}
          >
            <Box className="splitter-button">{secondLink.text}</Box>
          </SplitBox>
        </Grid>
      </Grid>
    </HomePageFullScreenSection>
  );
}

export default Splitter;
