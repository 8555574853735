import React from "react";
import Splitter from "./Splitter";

function TheEnd() {
  const firstLink = {
    path: "/meet-team",
    text: (
      <span>
        Meet
        <br />
        the Team
      </span>
    ),
  };
  const secondLink = {
    path: "/schedule-meeting",
    text: "Request Information",
  };

  return <Splitter firstLink={firstLink} secondLink={secondLink} />;
}

export default TheEnd;
