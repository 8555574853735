import React from "react";
import { useLocation } from "react-router-dom";
import { Typography } from "@mui/material";
import gsap from "gsap";

function NavbarTitle() {
  const titleRef = React.useRef();
  const location = useLocation();

  React.useEffect(() => {
    const path = location.pathname;
    let title;
    if (path.charAt(0) === "/") {
      title = path.substring(1);
    }
    switch (title) {
      case "404":
        title = "Priority 6";
        break;

      case "meet-team":
        title = "the team";
        break;

      case "schedule-meeting":
        title = "request information";
        break;

      default:
        break;
    }
    titleRef.current.innerHTML = title;
    gsap.to(titleRef.current, { autoAlpha: 1, duration: 0.2 });
  }, [location]);

  return (
    <Typography
      align="center"
      component="div"
      sx={{
        flexGrow: 1,
        visibility: "hidden",
        textTransform: "uppercase",
        fontSize: "15px",
        textDecoration: "underline",
        textUnderlineOffset: "5px",
        textDecorationThickness: "5px",
      }}
      ref={titleRef}
    ></Typography>
  );
}

export default NavbarTitle;
