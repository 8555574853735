import * as React from "react";
import { useLocation } from "react-router-dom";
import { AppBar, Box, Toolbar } from "@mui/material";
// import { AppBar, Box, IconButton, Toolbar } from "@mui/material";
// import MenuIcon from "@mui/icons-material/Menu";
// import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material";
import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import HomePageNavbar from "./HomePageNavbar";
import NavbarTitle from "./NavbarTitle";
import Menu from "./Menu";
import { AppContext } from "../App";
import { Spin as Hamburger } from 'hamburger-react'
import HomePageSections from "./Pages/Home-Page-Sections/HomePageSections";

const LogoText = styled(Typography)({
  position: 'fixed',
  color: '#4d84e1',
  backgroundColor: "rgba(0,0,0,0.0)",
  backgroundImage: "none",
  fontFamily: 'Dioropen',
  fontSize: '18px',
  WebkitTextRendering: 'optimizeSpeed',
  textRendering: 'optimizeSpeed',
  textShadow: '2px 2px 4px rgba(0, 0, 0, 0.6)',
  align: 'left',
  paddingTop: '20px',
  paddingLeft: '26px',
});

function Navbar() {
  const containerRef = React.useRef();
  const [isOpen, setOpen] = React.useState(false)
  const [onHomePage, setOnHomePage] = React.useState();
  const theme = useTheme();
  const location = useLocation();
  const { state, handleLogout } = React.useContext(AppContext);
  const isUserManagementPage = location.pathname === '/user-management';

  // Determine the invertNavbar value based on the current section
  const currentSection = HomePageSections.find(section => location.pathname.includes(section.path));
  const invertNavbar = currentSection && currentSection.invertNavbar;

  React.useEffect(() => {
    setOnHomePage(location.pathname.includes("/home"));
  }, [location]);

  return (
    <Box
      ref={containerRef}
      sx={{ flexGrow: 1, visibility: state.isLoggedIn ? "visible" : "hidden" }}
    >
      {state.isLoggedIn && (
        <>
          <AppBar
            position="fixed"
            sx={{
              backgroundColor: "rgba(0,0,0,0)",
              color: invertNavbar && !isOpen ? 'darkblue' : 'white',
              backgroundImage: "none",
              boxShadow: "none",
              height: "64px",
              minHeight: "64px",
            }}
          >
            <LogoText>6</LogoText>
            <Toolbar>
              {!isUserManagementPage && (
                <Box
                  sx={{
                    width: "100%",
                    overflow: "visible",
                    [theme.breakpoints.down("sm")]: {
                      overflow: "hidden",
                    },
                    visibility: isOpen ? "hidden" : "visible",
                  }}
                >
                  {onHomePage && <HomePageNavbar invertNavbar={invertNavbar} isMenuOpen={isOpen} />}
                  {!onHomePage && <NavbarTitle />}
                </Box>
              )}
            </Toolbar>
            <div style={{ position: 'absolute', top: '0.5rem', right: '1rem' }}>
              {state.isLoggedIn && <Hamburger size={20} toggled={isOpen} toggle={setOpen}/>}
            </div>
          </AppBar>
          <Menu menuOpen={isOpen} toggleMenuOpen={setOpen} handleLogout={handleLogout} />
        </>
      )}
    </Box>
  );
}

export default Navbar;
