import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";
import { Box, CircularProgress } from "@mui/material";
import Content from "./Components/Content";
import Navbar from "./Components/Navbar";
import SectionTransitionWipe from "./Components/SectionTransitionWipe";
import ScrollDownIndicator from "./Components/ScrollDownIndicator";
import CopyrightNotice from "./Components/CopyrightNotice";
import "./App.css";
import axios from "axios";

export const AppContext = React.createContext(null);

export const sectionTransitionWipeRef = React.createRef();
export const scrollDownIndicatorRef = React.createRef();

const serverUrl = process.env.REACT_APP_SERVER_URL ? process.env.REACT_APP_SERVER_URL : 'https://bbo1.rocks/server';

function App() {
  const [state, setState] = useState({
    isLoggedIn: false,
    userRole: null,
    userName: null,
    isLoading: true,
  });
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuth = async () => {
      // Check for stored authentication data when the app loads
      const authToken = localStorage.getItem("authToken");
      const userRole = localStorage.getItem("userRole");
      const userName = localStorage.getItem("userName");

      if (authToken && userRole && userName) {
        try {
          const response = await axios.get(`${serverUrl}/api/verify-token`, {
            headers: { Authorization: `Bearer ${authToken}` },
          });
          if (response.status === 200) {
            setState({
              isLoggedIn: true,
              userRole: userRole,
              userName: userName,
              isLoading: false,
            });
            return;
          }
        } catch (error) {
          console.error("Token verification failed:", error);
        }
      }

      // If we reach here, either there was no token or verification failed
      setState({
        isLoggedIn: false,
        userRole: null,
        userName: null,
        isLoading: false,
      });
    };

    checkAuth();
  }, []);

  // Login handler
  const handleLogin = (role) => {
    setState({
      isLoggedIn: true,
      userRole: role,
      userName: localStorage.getItem("userName"),
      isLoading: false,
    });
  };

  // Logout handler
  const handleLogout = async () => {
    const token = localStorage.getItem("authToken");
    // Send a request to the backend's /logout route
    axios.post(`${serverUrl}/api/logout`, null, {
      headers: { Authorization: `Bearer ${token}` },
    }).catch((error) => {
      console.error('Error during logout:', error);
    }).finally(() => {
      // Clear user data from local storage and context
      localStorage.removeItem("userRole");
      localStorage.removeItem("authToken");
      localStorage.removeItem("userName");

      setState({
        isLoggedIn: false,
        userRole: null,
        userName: null,
      });
      navigate('/');
    });
  };

  const contextValue = {
    state,
    handleLogin,
    handleLogout,
  };

  if (state.isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <AppContext.Provider value={contextValue}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Box className="App" sx={{ backgroundColor: "#000" }}>
          <Navbar sx={{ visibility: state.isLoggedIn ? "visible" : "hidden" }}/>
          <Content />
          <SectionTransitionWipe ref={sectionTransitionWipeRef} />
          <ScrollDownIndicator ref={scrollDownIndicatorRef} />
          <CopyrightNotice/>
        </Box>
      </ThemeProvider>
    </AppContext.Provider>
  );
}

export default App;
