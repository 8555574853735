// components/Disclaimer.js
import React, { useEffect, useRef } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { styled } from '@mui/system';


const DisclaimerContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(4),
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[2],
  maxWidth: 800,
  margin: '0px, 0px, 96px',
  height: '90%',
  overflow: 'hidden',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
    maxHeight: '85vh',
  },
}));

const DisclaimerTitle = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  color: theme.palette.primary.main,
  textAlign: 'center',
  fontFamily: 'Normal Light',
  fontSize: '2rem',
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.25rem',
  },
}));

const DisclaimerText = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  textAlign: 'left',
  lineHeight: 1.5,
  fontSize: '1rem',
  overflowY: 'auto',
  paddingRight: theme.spacing(1),
  fontFamily: 'Normal Light',
  maxHeight: 'calc(100% - 140px)',
  scrollbarWidth: 'thin',
  scrollBehavior: 'smooth',
  '&::-webkit-scrollbar': {
    width: '8px',  // Make the scrollbar thinner (for Webkit browsers like Chrome)
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.grey[400],  // Style the scrollbar thumb
    borderRadius: '10px',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.8rem',
    maxHeight: 'calc(100% - 180px)',
  },
}));

const ButtonContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  margin: '0px, 0px, 0px, 0px',
  marginTop: theme.spacing(4),
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    width: '90%',
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  padding: theme.spacing(1.5),
  [theme.breakpoints.down('sm')]: {
    width: '45%',
    fontSize: '0.75rem',
    padding: theme.spacing(1),
  },
}));

const Disclaimer = ({ onAccept, onDecline }) => {
  const textRef = useRef(null);  // Ref to the text container

  useEffect(() => {
    // Custom mouse wheel listener for smoother scrolling
    const handleWheel = (e) => {
      if (textRef.current) {
        textRef.current.scrollTop += e.deltaY;  // Scroll manually
        e.preventDefault();  // Prevent default behavior
      }
    };

    const textElement = textRef.current;
    if (textElement) {
      textElement.addEventListener('wheel', handleWheel, { passive: false });
    }

    return () => {
      if (textElement) {
        textElement.removeEventListener('wheel', handleWheel);
      }
    };
  }, []);

  return (
    <DisclaimerContainer>
      <DisclaimerTitle variant="h4">Confidential Information Notice</DisclaimerTitle>
      <DisclaimerText>
        Welcome to Priority 6's BBO1 website. This site contains proprietary and
        confidential information accessible only to individuals and entities bound
        by a Non-Disclosure Agreement (NDA) with Priority 6, Inc. By accessing this
        site, you acknowledge your responsibility to safeguard the information as
        required by your NDA.
        <br/>
        <br/>
        Please be advised that Priority 6, Inc. actively  monitors site access, including
        login sessions, IP addresses, and usage patterns, for the purpose of ensuring
        compliance with confidentiality obligations and applicable privacy laws. Any
        unauthorized use, copying, distribution, reproduction of content, or any other misuse
        of the information provided, is strictly prohibited and may result in legal action
        in accordance with the terms of your NDA and applicable laws.
        <br/>
        <br/>
        By continuing to access this site, you agree to:
        <br/>
        1. Maintain the confidentiality of all disclosed information.
        <br/>
        2. Use the information only for the purposes authorized under your NDA.
        <br/>
        3. Refrain from sharing, distributing, or disclosing any information to
        third parties not covered by the NDA, whether verbally, electronically,
        or in any other form.
        <br/>
        4. Avoid unauthorized actions such as copying, downloading, or storing
        site content outside of the intended use permitted by the NDA.
        <br/>
        <br/>
        For any questions regarding your NDA obligations, please contact us at
        bbo1@priority6.us.
        <br/>
        <br/>
        Thank you for your cooperation.
      </DisclaimerText>
      <ButtonContainer>
        <StyledButton variant="contained" color="primary" onClick={onAccept} sx={{ marginRight: 2 }}>
          Accept
        </StyledButton>
        <StyledButton variant="outlined" color="primary" onClick={onDecline}>
          Decline
        </StyledButton>
      </ButtonContainer>
    </DisclaimerContainer>
  );
};

export default Disclaimer;