import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import gsap from "gsap";
import { ScrollTrigger, ScrollSmoother } from "gsap/all";
import HomePageSections from "./Pages/Home-Page-Sections/HomePageSections";
import { sectionTransitionWipeRef } from "../App";

gsap.registerPlugin(ScrollTrigger, ScrollSmoother);

function NavbarLinkWrapper({ children, sectionTitle }) {
  const location = useLocation();
  const navigate = useNavigate();
  const currentSectionIndex = React.useRef();
  const isCurrentSection = React.useRef(false);
  const thisSectionIndex = React.useRef();
  const smootherRef = React.useRef();

  // init component
  React.useEffect(() => {
    // set this link's index
    HomePageSections.forEach((section, i) => {
      if (section.title === sectionTitle) {
        thisSectionIndex.current = i;
      }
    });
    // set the current section index
    setSectionIndexFromLocation(location.pathname);
    smootherRef.current = ScrollSmoother.get();
    // eslint-disable-next-line
  }, []);

  // update current section index on location change
  React.useEffect(() => {
    setSectionIndexFromLocation(location.pathname);
    // eslint-disable-next-line
  }, [location]);

  function setSectionIndexFromLocation(path) {
    if (path === "/home" || path === "/home/") {
      currentSectionIndex.current = 0;
    } else {
      HomePageSections.forEach((section, i) => {
        if (path.includes(section.path)) {
          currentSectionIndex.current = i;
        }
      });
    }
  }

  // Transition direction based on if current section is "up" or "down" the Home Page from this link's section
  function chooseTransitionDirection() {
    return currentSectionIndex.current > thisSectionIndex.current
      ? sectionTransitionWipeRef.current.transitionUp()
      : sectionTransitionWipeRef.current.transitionDown();
  }

  function getPathFromIndex(index) {
    return index === 0 ? "" : HomePageSections[index].path;
  }

  function handleClick(e) {
    if (isCurrentSection.current === false) {
      chooseTransitionDirection();
      // wait for panel animation to cover the screen, then navigate
      gsap.delayedCall(0.75, () => {
        smootherRef.current.scrollTo(0, false);
        navigate(`home/${getPathFromIndex(thisSectionIndex.current)}`);
      });
    }
  }

  return (
    <Box
      onClick={() => {
        handleClick();
      }}
    >
      {children}
    </Box>
  );
}

export default NavbarLinkWrapper;
