import React from "react";
import { Typography, Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import MenuLink from "../MenuLink";

function FourZeroFour() {
  const theme = useTheme();

  return (
    <Box
      sx={{
        height: "100vh",
        width: "100%",
        position: "fixed",
        top: 0,
        left: 0,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        background: theme.palette.colors.darkBlue,
      }}
    >
      <Box>
        <Typography
          variant="h1"
          sx={{
            fontSize: "40px",
            [theme.breakpoints.up("sm")]: {
              fontSize: "44px",
            },
            [theme.breakpoints.up("md")]: {
              fontSize: "56px",
            },
            [theme.breakpoints.up("lg")]: {
              fontSize: "60px",
            },
          }}
          align="center"
        >
          Page Not Found
        </Typography>
        <Typography
          variant="h2"
          align="center"
          sx={{
            marginTop: "20px",
          }}
        >
          <MenuLink to="/home">Home</MenuLink>
        </Typography>
      </Box>
    </Box>
  );
}

export default FourZeroFour;
