import { createTheme } from "@mui/material/styles";
import "./fonts/font-face.css";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 768,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: {
    mode: "dark",
    colors: {
      darkBlue: "#08102e",
      contentBorder: "#e6e6e6",
    },
    secondary: {
      main: "#eee",
    },
  },
  typography: {
    fontFamily: [
      "Normal Light",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    h1: {
      fontFamily: [
        "Normal Bold",
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
      ].join(","),
    },
  },
});

export default theme;
