import React from "react";
import { Box } from "@mui/material";
import gsap from "gsap";

function Page({ children }) {
  const containerRef = React.useRef();
  const pageTL = React.useRef();

  React.useEffect(() => {
    if (window.history.scrollRestoration) {
      window.history.scrollRestoration = "manual";
    }
    return () => {
      // Reset scrollRestoration to 'auto' or another appropriate value when the component unmounts
      window.history.scrollRestoration = "auto";
    };
  }, []);

  React.useLayoutEffect(() => {
    pageTL.current = gsap.timeline({
      onStart: () => {
        window.scrollTo(0, 0);
      },
    });
    pageTL.current.to(containerRef.current, {
      autoAlpha: 1,
      duration: 0.3,
    });
    return () => {
      pageTL.current.kill();
    };
  }, []);

  return (
    <Box
      ref={containerRef}
      sx={{
        visibility: "hidden",
        overflowX: "hidden",
        paddingTop: "64px",
      }}
    >
      {children}
    </Box>
  );
}

export default Page;
