import React from "react";
import { Box } from "@mui/material";

function HomePageFullScreenSection({ children, backgroundColor }) {
  const [viewportHeight, setViewportHeight] = React.useState(
    window.innerHeight,
  );

  React.useLayoutEffect(() => {
    window.addEventListener("resize", handleResize);
    function handleResize() {
      setViewportHeight(window.innerHeight);
    }

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Box
      sx={{
        height: `${viewportHeight}px`,
        width: "100%",
        marginTop: "-64px",
        backgroundColor: backgroundColor ? backgroundColor : "#333",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {children}
    </Box>
  );
}

export default HomePageFullScreenSection;
