import React from "react";
import { Typography, Box } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import Page from "./Page";
import gsap from "gsap";

function Contact() {
  const StyledTypography = styled(Typography)(({ theme }) => ({
    color: "#fff",
    fontFamily: "Normal Bold",
    fontSize: "30px",
    fontWeight: 700,
    lineHeight: 1.4,
    [theme.breakpoints.up("sm")]: {
      fontSize: "36px",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "48px",
    },
  }));

  const theme = useTheme();

  React.useEffect(() => {
    window.scrollTo(0, 0);
    gsap.delayedCall(0.3, () => {
      // smoother.current.paused(true);
    });
    return () => {
      gsap.delayedCall(0.1, () => {
        // eslint-disable-next-line
        // smoother.current.paused(false);
      });
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Page>
      <Box
        sx={{
          height: "100vh",
          width: "100%",
          top: 0,
          left: 0,
          position: "fixed",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          paddingBottom: "100px",
          backgroundColor: theme.palette.colors.darkBlue,
        }}
      >
        <StyledTypography variant="h1">email:</StyledTypography>
        <StyledTypography variant="h2">bbo1@priority6.us</StyledTypography>
      </Box>
    </Page>
  );
}

export default Contact;
