import React from "react";
import { Box } from "@mui/material";
import { ReactComponent as SVG } from "../../../images/play2-optimized.svg";
import "../../../styles/video-modal.css";

function PlayButton({ onClick }) {
  const containerRef = React.useRef();

  React.useEffect(() => {}, []);

  const handleClick = (event) => {
    event.preventDefault();
    if (onClick) {
      onClick();
    }
  };

  return (
    <Box
      ref={containerRef}
      className="video-popup-play"
      onClick={handleClick}
      onTouchStart={handleClick}
      sx={{
        height: "70px",
        width: "70px",
        borderRadius: "50%",
        backgroundColor: "transparent",
        marginBottom: "26px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        cursor: "pointer",
        "& svg": {
          height: "30px",
          width: "30px",
          lineHeight: "42px",
        },
      }}
    >
      <SVG />
    </Box>
  );
}

export default PlayButton;
