import React, { useContext, useEffect }  from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { Box } from "@mui/material";
import Login from "./Pages/Login";
import UserManagement from "./Pages/UserManagement";
import Home from "./Pages/Home";
import HomePageSections from "./Pages/Home-Page-Sections/HomePageSections";
import Team from "./Pages/Team";
import Contact from "./Pages/Contact";
import FourZeroFour from "./Pages/FourZeroFour";
import { AppContext } from "../App";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import ScrollSmoother from "gsap/ScrollSmoother";

gsap.registerPlugin(ScrollTrigger, ScrollSmoother);

function Content() {
  const { state, handleLogin, handleLogout } = useContext(AppContext);
  const smootherRef = React.useRef();
  const copiedSections = HomePageSections.slice();
  const firstSection = copiedSections.shift();
  const FirstComponent = firstSection.component;

  useEffect(() => {
    // Check if there's a stored auth token when the Content component mounts
    const authToken = localStorage.getItem("authToken");
    if (authToken && !state.isLoggedIn) {
      const userRole = localStorage.getItem("userRole");
      handleLogin(userRole);
    }
  }, [state.isLoggedIn, handleLogin]);

  React.useLayoutEffect(() => {
    const smoother = ScrollSmoother.create({
      content: smootherRef.current,
      smooth: 1,
      normalizeScroll: true,
      smoothTouch: 0.01,
    });

    return () => {
      smoother.kill();
    };
  }, []);
  if (state.isLoading) {
    return null; // or a loading spinner if you prefer
  }
  return (
    <Box ref={smootherRef} id="smooth-content">
      <Routes>
        <Route path="/" element={<Login handleLogin={handleLogin} handleLogout={handleLogout}/>} />
        <Route
          path="/user-management"
          element={
            state.isLoggedIn && state.userRole === "admin" ? (
              <UserManagement />
            ) : (
              <Navigate to="/home" />
            )
          }
        />
        <Route
          path="home"
          element={state.isLoggedIn ? <Home /> : <Navigate to="/" />}
        >
          <Route index element={<FirstComponent />} />
          {copiedSections.map((section, i) => {
            return (
              <Route
                key={section.path}
                path={`${section.path}`}
                element={
                  state.isLoggedIn ? <section.component /> : <Navigate to="/" />
                }
              />
            );
          })}
        </Route>
        <Route
          path="/meet-team"
          element={state.isLoggedIn ? <Team /> : <Navigate to="/" />}
        />
        <Route
          path="/schedule-meeting"
          element={state.isLoggedIn ? <Contact /> : <Navigate to="/" />}
        />
        <Route
          path="/404"
          element={state.isLoggedIn ? <FourZeroFour /> : <Navigate to="/" />}
        />
        <Route
          path="*"
          element={
            state.isLoggedIn ? <Navigate to="404" /> : <Navigate to="/" />
          }
        />
      </Routes>
    </Box>
  );
}

export default Content;
