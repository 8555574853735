import React, { useEffect } from "react";
import { Box } from "@mui/material";
import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import FireVideo from "../../../videos/Digital_Fire.webm";
import FirePoster from "../../../images/Digital_Fire.webp";
import HomePageFullScreenSection from "./HomePageFullScreenSection";
import VideoModal from "./VideoModal";
import video from "../../../videos/BBO1_Fire.webm";

gsap.registerPlugin(ScrollToPlugin);

function Catalyst() {
  useEffect(() => {
    // GSAP animation logic
    const gsapAnimation = gsap.to(window, {
      scrollTo: { y: "#targetElement", autoKill: false },
      duration: 0.5,
      onComplete: () => {
        // Delay video playback by 0.5 seconds after GSAP animation completes
        const timeoutId = setTimeout(() => {
          const videoElement = document.getElementById("fireVideo");
          if (videoElement) {
            videoElement.play();
          }
        }, 500); // 500 milliseconds = 0.5 seconds
        // Cleanup function to clear the timeout if the component is unmounted
        return () => clearTimeout(timeoutId);
      },
    });
    // Cleanup function to kill the GSAP animation if the component is unmounted
    return () => {
      gsapAnimation.kill();
    };
  }, []);
  return (
    <HomePageFullScreenSection backgroundColor="transparent">
      <Box
        sx={{
          zIndex: 10,
          height: "100%",
          width: "100%",
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          userSelect: 'none',
        }}
      >
        <VideoModal text="Generational Wealth" video={video} />
      </Box>
      {/* video background */}
      <Box
        sx={{
          height: "100vh",
          width: "100vw",
          position: "fixed",
          overflow: "hidden",
          top: 0,
          backgroundColor: "#000",
          left: 0,
          zIndex: -1,
          opacity: 0.93,
          "& video": {
            height: "100%",
            width: "100%",
            objectFit: "cover",
            objectPosition: "center center",
          },
        }}
      >
        <video
          id="fireVideo"
          src={FireVideo}
          poster={FirePoster}
          disablePictureInPicture
          playsInline
          autoPlay
          loop
          volume={0}
          muted
        />
      </Box>
    </HomePageFullScreenSection>
  );
}

export default Catalyst;