import React from "react";
import { Box, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import abstractVideo from "../../../videos/digital-cyberspace-01388.webm";
import abstractPoster from "../../../images/digital-cyberspace-01388.webp";
import StarWarsText from "../../StarWarsText";

gsap.registerPlugin(ScrollTrigger);

function Problem() {
  const containerRef = React.useRef();
  const videoRef = React.useRef();
  const abstractVideoContainerRef = React.useRef();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  React.useEffect(() => {
    document.body.appendChild(abstractVideoContainerRef.current);
    return () => {
      document.querySelector("#abstractVideo").remove();
    };
  }, []);

  React.useEffect(() => {
    const videoElement = videoRef.current;

    const cleanupVideo = () => {
      if (videoElement) {
        videoElement.pause();
        videoElement.removeAttribute("src");
        videoElement.load();
      }
    };

    return () => {
      cleanupVideo();
    };
  }, []);

  return (
    <Box>
      <Box
        sx={{
          backgroundColor: "transparent",
          position: "relative",
          visibility: "visible",
        }}
      >
        <Box
          ref={containerRef}
          sx={{
            position: "relative",
            zIndex: 100,
            "& a": {
              color: "#fff",
            },
          }}
        >
          {/* text */}
          <StarWarsText isSmallScreen={isSmallScreen} specialLastLine={true}>
            <span className="text-32">Discovering and monetizing Earth's natural resources is slow, inefficient, and capital-intensive.</span><br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <span className="text-32">Pebble Mine has seen well over $1.5B of investment since its discovery in 1987.</span><br/>
            <span className="text-32">Not one ounce of value has been extracted.</span><br/>
            <br/>
            <br/>
            <br/>
            <br/><span className="text-32">Currently, in the United States there are:</span><br/>
            <br/>
            <span className="text-70">528,418</span> <span className="text-32">active mining claims.</span><br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <span className="text-70">12,563</span> <span className="text-38">or less than 2%, earn revenue.</span><br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <span className="text-32">Like Pebble Mine,</span><br/>
            <span className="text-70">515,855</span> <span className="text-38">claims don't make a dime!</span><br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <span className="text-38">These owners pay the government $103,171,000 a year to maintain illiquid, unprofitable claims.</span><br/><br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <span className="text-32">We have invented a mechanism to solve Pebble Mine's problem and bring liquidity to every mining claim,</span><br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <span className="text-32">driving efficiency to discover, monetize and meet the surging demand for natural resources.</span><br/>
            <br />
          </StarWarsText>
        </Box>
      </Box>
      {/* video background */}
      <Box
        ref={abstractVideoContainerRef}
        id="abstractVideo"
        sx={{
          height: "100vh",
          width: "100vw",
          position: "fixed",
          overflow: "hidden",
          top: 0,
          backgroundColor: "#000",
          left: 0,
          zIndex: -1,
          opacity: 0.63,
          "& video": {
            height: "100%",
            width: "100%",
            objectFit: "cover",
            objectPosition: "center center",
          },
        }}
      >
        <video
          id="bgvideo"
          ref={videoRef}
          src={abstractVideo}
          poster={abstractPoster}
          disablePictureInPicture
          playsInline
          autoPlay
          loop
          volume={0}
          muted
        />
      </Box>
    </Box>
  );
}

export default Problem;
