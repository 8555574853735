import React from "react";
import gsap from "gsap";
import { ScrollTrigger, ScrollSmoother } from "gsap/all";
import Page from "./Page";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { sectionTransitionWipeRef, scrollDownIndicatorRef } from "../../App";
import HomePageSections from "./Home-Page-Sections/HomePageSections";

gsap.registerPlugin(ScrollTrigger, ScrollSmoother);

function Home() {
  const touchStartPosition = React.useRef();
  const readyForTransitionDown = React.useRef(false);
  const readyForTransitionUp = React.useRef(false);
  const sectionTransitionTriggered = React.useRef(false);
  const sectionIndex = React.useRef(0);
  const smootherRef = React.useRef();
  const lastTransitionDirection = React.useRef("");
  const transitionBlocked = React.useRef(true);
  const initialScroll = React.useRef(true);
  const location = useLocation();
  const navigate = useNavigate();
  const endIndex = HomePageSections.length - 1;
  const sectionTransitionLockoutDelay = 1.5;
  const sectionTransitionDelay = 0.5;
  const scrollDownIndicatorInitialRevealDelay = 2;

  // cleanup on component dismount
  React.useEffect(() => {
    console.log("useEffect ");
    ScrollTrigger.killAll();
    setupHandlers();
    return () => {
      cleanupHandlers();
      if (scrollDownIndicatorRef.current) {
        scrollDownIndicatorRef.current.instantHide();
      }
      sectionIndex.current = 0;
    };
    // eslint-disable-next-line
  }, []);

  // handle path location changes
  React.useEffect(() => {
    console.log("useEffect location changed " + location.pathname);
    scrollDownIndicatorRef.current.colorSchemeWhite();
    setIndexFromPath(location.pathname);
    smootherRef.current = ScrollSmoother.get();
    readyForTransitionDown.current = false;
    readyForTransitionUp.current = false;
    sectionTransitionTriggered.current = false;
    transitionBlocked.current = true;
    initialScroll.current = true;
    setupHandlers();

    // on any section but the last, wait before showing scroll down indicator
    if (sectionIndex.current !== endIndex) {
      gsap.delayedCall(scrollDownIndicatorInitialRevealDelay, () => {
        scrollDownIndicatorRef.current.show();
      });
    } else {
      scrollDownIndicatorRef.current.instantHide();
    }

    // wait before enabling scroll after a transition
    gsap.delayedCall(sectionTransitionDelay, () => {
      smootherRef.current.paused(false);
    });

    // wait after transition completes to allow another transition.
    // avoids a big scroll moving between multiple sections
    gsap.delayedCall(sectionTransitionLockoutDelay, () => {
      transitionBlocked.current = false;
    });

    return () => {
      cleanupHandlers();
    };

    // eslint-disable-next-line
  }, [location]);

  // handle scroll down indicator click
  React.useEffect(() => {
    console.log("useEffect everything");
    const chevronSVG = scrollDownIndicatorRef.current.getSVGRef();
    chevronSVG.current.addEventListener("click", handleScrollDownClick);

    // cleanup click handler
    return () => {
      chevronSVG.current.removeEventListener("click", handleScrollDownClick);
    };

    // eslint-disable-next-line
  }, []);

  function handleScrollDownClick() {
    if (sectionIndex.current !== endIndex && sectionTransitionTriggered.current === false) {
      sectionTransitionTriggered.current = true;
      console.log("handleScrollDownClick down " + sectionIndex.current + " " + endIndex);
      sectionTransition("down");
    }
  }

  // set the current section index from the current location.pathname
  function setIndexFromPath(path) {
    if (path === "/home" || path === "/home/") {
      sectionIndex.current = 0;
    } else {
      HomePageSections.forEach((section, i) => {
        if (path.includes(section.path)) {
          sectionIndex.current = i;
        }
      });
    }
  }

  function getPathFromIndex(index) {
    return index === 0 ? "" : HomePageSections[index].path;
  }

  let timerID;

  function handleWheelScrollEvent(e) {
    const isVideoElement = e.srcElement?.tagName === 'VIDEO' || e.target?.tagName === 'VIDEO';

    if (isVideoElement) {
      return;
    }

    // const direction = e.deltaY >= 0 ? "down" : "up";
    clearTimeout(timerID);
    let direction;
    if (e.deltaY > 0) {
      direction = "down";
      console.log('declare down');
    } else {
      direction = "up";
    }

    let readyForTransitionDown = false;
    // 0.99 so that the screen transitions at the end vs being stuck if given a value of 1 or higher
    if (isFullScreenSection() || smootherRef.current.progress >= 0.9999) {
      readyForTransitionDown = true;
    }

    let readyForTransitionUp = false;
    if (isFullScreenSection() || smootherRef.current.progress <= 0) {
      readyForTransitionUp = true;
    }

    console.log(`Smoother progress: ${smootherRef.current.progress}`);

    if (direction === "down" && readyForTransitionDown === true && sectionTransitionTriggered.current === false &&
        transitionBlocked.current === false)
    {
      // Do not add delays here is causes issues.
      handleNavigation("down");

    }
    else if (direction === "up" && readyForTransitionUp === true && sectionTransitionTriggered.current === false &&
               transitionBlocked.current === false)
    {
      handleNavigation("up");
    }
  }

  function handleTouchStartEvent(e) {
    touchStartPosition.current = e.touches[0].clientY;
  }

  function handleTouchEndEvent(e) {
    const touchCurrentPosition = e.changedTouches[0].clientY;
    const direction =
      touchStartPosition.current > touchCurrentPosition ? "down" : "up";

    let readyForTransitionDown = false;
    if (isFullScreenSection() || smootherRef.current.progress === 1) {
      readyForTransitionDown = true;
    }

    let readyForTransitionUp = false;
    if (isFullScreenSection() || smootherRef.current.progress <= 0.02) {
      readyForTransitionUp = true;
    }

    if (direction === "down" && readyForTransitionDown === true && sectionTransitionTriggered.current === false &&
        transitionBlocked.current === false)
    {
      handleScrollDownClick();
      handleNavigation("down");
    }
    else if (direction === "up" && readyForTransitionUp === true && sectionTransitionTriggered.current === false &&
             transitionBlocked.current === false)
    {
      handleNavigation("up");
    }
  }

  function handleNavigation(direction) {
    if (direction === "down" && sectionIndex.current !== endIndex && sectionTransitionTriggered.current === false) {
      sectionTransitionTriggered.current = true;
      sectionTransition(direction);
    }
    if (direction === "up" && sectionIndex.current !== 0 && sectionTransitionTriggered.current === false) {
      sectionTransitionTriggered.current = true;
      sectionTransition(direction);
    }
  }

  // handle the section transition: play panel animation, navigate to new route
  function sectionTransition(direction) {
    lastTransitionDirection.current = direction;
    scrollDownIndicatorRef.current.hide();
    if (smootherRef.current.paused() === false) {
      smootherRef.current.paused(true);
      cleanupHandlers();

     // choose section transition
     direction === "up" ? sectionTransitionWipeRef.current.transitionUp() : sectionTransitionWipeRef.current.transitionDown();
     // Use GSAP delayedCall to wait for the transition to cover the screen before navigating
     gsap.delayedCall(sectionTransitionDelay, () => {
       // Determine the transition direction and set scroll position accordingly
       if (direction === "up") {
         sectionIndex.current--;
         // If the transition is "up", navigate to the new page first
         navigate(getPathFromIndex(sectionIndex.current));
         // Then scroll to the bottom after a short delay to allow the page to render
         gsap.delayedCall(0.1, () => {
           const content = smootherRef.current.content();
           const animationDuration = 0.1; // Adjust this value based on your animation duration

           gsap.delayedCall(animationDuration, () => {
             smootherRef.current.scrollTo(content.scrollHeight, false);
           });
         });
       } else {
         // If the transition is "down", scroll to the top
         sectionIndex.current++;
         smootherRef.current.scrollTo(0, false);
         // Navigate to the new page
         navigate(getPathFromIndex(sectionIndex.current));
       }
     });
    }
  }

  function setupHandlers() {
    // handle mouse wheel or trackpad input
    window.addEventListener("wheel", handleWheelScrollEvent);

    // handle touchscreen input
    window.addEventListener("touchstart", handleTouchStartEvent);
    window.addEventListener("touchend", handleTouchEndEvent);
  }

  function cleanupHandlers() {
    window.removeEventListener("wheel", handleWheelScrollEvent);
    window.removeEventListener("touchstart", handleTouchStartEvent);
    window.removeEventListener("touchend", handleTouchEndEvent);
  }

  function isFullScreenSection() {
    return HomePageSections[sectionIndex.current].fullScreen;
  }

  return (
    <Page>
      <Outlet />
    </Page>
  );
}

export default Home;
